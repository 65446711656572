import React from 'react';
import { HouseholdMember } from './MultiStepForm';

interface Step1Props {
  currentMember: Partial<HouseholdMember>;
  hasExecutiveChef: boolean;
  updateCurrentMember: (key: keyof HouseholdMember, value: any) => void;
  nextStep: () => void;
  calculateAge: (birthYear: number, birthMonth: number) => number;
}

const Step1: React.FC<Step1Props> = ({ currentMember, hasExecutiveChef, updateCurrentMember, nextStep, calculateAge }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    updateCurrentMember(name as keyof HouseholdMember, value);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    updateCurrentMember(name as keyof HouseholdMember, checked);
  };

  // Get the current year and month
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  
  return (
    <div className='form-container'>
      <h3>Add a household member</h3>
      <label htmlFor="name">Name</label>
      <input type="text" id="name" name="name" value={currentMember.name || ''} onChange={handleInputChange} />
      <label htmlFor="birthMonth">Birth Month</label>
      <select id="birthMonth" name="birthMonth" value={currentMember.birthMonth || ''} onChange={handleInputChange}>
        <option value="">Select month</option>
        {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => {
          if (currentYear === currentMember.birthYear && month > currentMonth) {
            return null;
          }
          return (
            <option key={month} value={month}>
              {new Date(0, month - 1).toLocaleString('en-US', { month: 'long' })}
            </option>
          );
        })}
      </select>
      <label htmlFor="birthYear">Birth Year</label>
      <select id="birthYear" name="birthYear" value={currentMember.birthYear || ''} onChange={handleInputChange}>
        <option value="">Select year</option>
        {Array.from({ length: currentYear - 1900 + 1 }, (_, i) => currentYear - i).map((year) => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>
      {!hasExecutiveChef && (
        <>
          <div className='container-row'>
            <input type="checkbox" id="isExecutiveChef" name="isExecutiveChef" checked={currentMember.isExecutiveChef || false} onChange={handleCheckboxChange} />
            <label id='isExecutiveChef' htmlFor="isExecutiveChef">Executive Chef</label>
          </div>
          {currentMember.isExecutiveChef && (
            <>
              <label htmlFor="skillLevel">Cooking Skill Level</label>
              <select id="skillLevel" name="skillLevel" value={currentMember.skillLevel || ''} onChange={handleInputChange}>
                <option value="">Select skill level</option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="expert">Expert</option>
                </select>
            </>
          )}
        </>
      )}
      <div className='row-container'>
        <button onClick={nextStep}>Next</button>
      </div>
    </div>
  );
};

export default Step1;