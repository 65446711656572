import React from 'react';
import { HouseholdMember } from './MultiStepForm';
import MealPlanGenerator from './MealPlanGenerator';

interface SummaryProps {
  householdMembers: HouseholdMember[];
  capturedIngredients: string[];
}

type MealPlanByDay = {
  [key: string]: string[];
};

const createPrompt = (householdMembers: HouseholdMember[], capturedIngredients: string[]) => {
  const membersInfo = householdMembers.map((member) => {
    const executiveChefInfo = member.isExecutiveChef ? `${member.name} is responsible for food ordering, prep, and overall household meal management. ${member.name}'s cooking skill level is ${member.skillLevel}.` : '';

    const mealPlan = (member.mealPlan || []).filter(meal => meal.active);
    const mealPlanByDay: MealPlanByDay = mealPlan.reduce((acc: MealPlanByDay, meal) => {
      if (!acc[meal.day]) {
        acc[meal.day] = [];
      }
      acc[meal.day].push(meal.mealType);
      return acc;
    }, {} as MealPlanByDay);

    const allDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const mealPlanSummary = allDays.map(day => {
      const meals = mealPlanByDay[day] ? mealPlanByDay[day].join(', ') : '';
      return `${day}: ${meals || 'no meals needed'}`;
    }).join('; ');

    const dietaryRestrictions = member.dietaryRestrictions || '';
    const dietaryPreferences = member.dietaryPreferences || '';
    const ageInfo = member.age ? `${member.name} is ${member.age} years old. ` : '';

    const dietaryRestrictionsInfo = dietaryRestrictions ? `${member.name}'s dietary restrictions: ${dietaryRestrictions}. ` : '';
    const dietaryPreferencesInfo = dietaryPreferences ? `${member.name}'s dietary preferences: ${dietaryPreferences}. ` : '';

    return `${ageInfo}${executiveChefInfo}${dietaryRestrictionsInfo}${dietaryPreferencesInfo}${member.name}'s meal plan: ${mealPlanSummary}`;
  });

  const ingredientsList = capturedIngredients.join(', ');

  const prompt = `Create a meal plan for the following household members: ${membersInfo.join('; ')}. Please consider the noted cooking skill level. Strictly enforce individual dietary restrictions, and use dietary preferences to loosely guide the meal-plan. As much as possible, try to ensure each meal is consistent across household member - eg. Chicken Strips for everyone for dinner as opposed to different dinners per household member. The following ingredients are on hand: ${ingredientsList}. Format the response by listing the day of the week starting with Monday and the Breakfast, Lunch, and Dinner meals as applicable listed below the day, iterating through each day of the week.`;
  return prompt;
};

const Summary: React.FC<SummaryProps> = ({
  householdMembers,
  capturedIngredients,
}) => {
  const prompt = createPrompt(householdMembers, capturedIngredients);
  
  return (
    <div className="summary-container">
              <hr />
      <MealPlanGenerator prompt={prompt} householdMembers={householdMembers} />
    </div>
  );
};

export default Summary;