import axios from 'axios';

const SERVER_URL = 'http://localhost:5000';

export async function recognizeIngredientsFromImage(image: string): Promise<{ name: string }[]> {
  try {
    const response = await axios.post(`${SERVER_URL}/recognize-ingredients`, {
      image: image,
    });

    return response.data.map((ingredient: string) => ({ name: ingredient }));
  } catch (error) {
    console.error('Error recognizing ingredients from image:', error);
    return [];
  }
}