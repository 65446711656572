import React from 'react';
import { HouseholdMember } from './MultiStepForm';
import '../Components.css';

interface Step3Props {
  currentMember: Partial<HouseholdMember>;
  updateCurrentMember: (key: keyof HouseholdMember, value: any) => void;
  prevStep: () => void;
  addHouseholdMember: () => void;
}

const Step3: React.FC<Step3Props> = ({
  currentMember,
  updateCurrentMember,
  prevStep,
  addHouseholdMember,
}) => {
  const days = React.useMemo(
    () => ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    []
  );

  const meals = React.useMemo(() => ['Breakfast', 'Lunch', 'Dinner'], []);

  React.useEffect(() => {
    if (!currentMember.mealPlan) {
      const defaultMealPlan = days.flatMap((day) =>
        meals.map((meal) => ({ day, mealType: meal, active: true }))
      );
      updateCurrentMember('mealPlan', defaultMealPlan);
    }
  }, [currentMember.mealPlan, days, meals, updateCurrentMember]);

  const toggleMeal = (day: string, meal: string) => {
    const updatedMeals = [...(currentMember.mealPlan || [])];
    const index = updatedMeals.findIndex((m) => m.day === day && m.mealType === meal);

    if (index === -1) {
      updatedMeals.push({ day, mealType: meal, active: true });
    } else {
      updatedMeals[index].active = !updatedMeals[index].active;
    }

    updateCurrentMember('mealPlan', updatedMeals);
  };

  const isMealActive = (day: string, meal: string): boolean => {
    const mealItem = (currentMember.mealPlan || []).find((m) => m.day === day && m.mealType === meal);
    return mealItem ? mealItem.active ?? true : true;
  };

  return (
    <div className="form-container">
      <h3>Schedule</h3>
      <table>
        <thead>
          <tr>
            <th></th>
            {days.map((day) => (
              <th key={day}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {meals.map((meal) => (
            <tr key={meal}>
              <td>{meal}</td>
              {days.map((day) => (
                <td key={day}>
                  <button
                    className={`meal-toggle ${isMealActive(day, meal) ? 'meal-active' : ''}`}
                    onClick={() => toggleMeal(day, meal)}
                  ></button>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="row-container">
        <button onClick={prevStep}>Previous</button>
        <button onClick={addHouseholdMember}>Save</button>
      </div>
    </div>
  );
};

export default Step3;