import React from 'react';
import Spinner from '../Spinner';
import '../Components.css';

interface Props {
  onClick: () => void;
  isLoading: boolean;
  disabled: boolean;
}

const BakeButton: React.FC<Props> = ({ onClick, isLoading, disabled }) => {
  return (
    <div className="BakeButton">
      <button onClick={onClick} disabled={disabled}>
      Bake My Casserole {isLoading && <Spinner />}
      </button>
    </div>
  );
};

export default BakeButton;