import React, { useState } from 'react';
import { HouseholdMember } from './MultiStepForm';
import { generateRecipe } from '../../services/OpenAIService';

interface MealPlanGeneratorProps {
  prompt: string;
  householdMembers: HouseholdMember[];
}

const MealPlanGenerator: React.FC<MealPlanGeneratorProps> = ({ prompt, householdMembers }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mealPlan, setMealPlan] = useState('');

  const generateMealPlan: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const result = await generateRecipe({ ingredients: prompt, useMealPlanPrompt: true });
      setMealPlan(result);
    } catch (error) {
      console.error('Error generating meal plan:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="summary-container">
      <button onClick={generateMealPlan} disabled={isLoading}>
        {isLoading ? 'Generating Meal Plan...' : 'Generate Household Meal Plan'}
      </button>
      {mealPlan && (
        <div className='meal-plan-container'>
          <h3>Meal Plan:</h3>
          <pre>{mealPlan}</pre>
        </div>
      )}
    </div>
  );
};

export default MealPlanGenerator;