import React, { useRef, useState } from 'react';
import { recognizeIngredientsFromImage } from '../../services/GoogleCloudService';
import Spinner from '../Spinner';
import '../Components.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { Menu, MenuItem } from '@mui/material';

interface Props {
  onImageSelected: (imageFile: File) => void;
  onAddIngredient: (ingredients: { name: string }[]) => void;
  onIngredientsDetected: (ingredients: { name: string }[]) => void;
  disabled: boolean;
}

const IngredientLens: React.FC<Props> = ({ onImageSelected, onAddIngredient, onIngredientsDetected, disabled }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleTakePhoto = () => {
    handleClose();
    if (fileInputRef.current) {
      fileInputRef.current.setAttribute('capture', 'environment');
      fileInputRef.current.click();
    }
  };

  const handleChooseFromLibrary = () => {
    handleClose();
    if (fileInputRef.current) {
      fileInputRef.current.removeAttribute('capture');
      fileInputRef.current.click();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setIsLoading(true);
      const imageFile = event.target.files[0];
      try {
        const base64Data = await readFileAsBase64(imageFile);
        const base64DataWithoutPrefix = base64Data.split(',')[1];
        const ingredients = await recognizeIngredientsFromImage(base64DataWithoutPrefix);
        console.log('Recognized ingredients:', ingredients);
        const newIngredients = ingredients.map((ingredient) => ({ name: ingredient.name }));
        onAddIngredient(newIngredients);
        onIngredientsDetected(newIngredients);
      } catch (error) {
        console.error('Error detecting objects from image:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result as string;
        resolve(base64Data);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="IngredientLens">
      <FontAwesomeIcon className="camera-icon" icon={faCamera} style={{ fontSize: '24px' }} />
      <p>Use image recognition to identify ingredients from a photo.</p>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleImageUpload}
        disabled={isLoading}
        style={{ display: 'none' }}
      />
<button onClick={handleClick} className={isLoading ? 'button-disabled' : ''}>
  Take a Picture {isLoading && <Spinner />}
</button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleTakePhoto}>Take a Photo...</MenuItem>
        <MenuItem onClick={handleChooseFromLibrary}>Choose from Library...</MenuItem>
      </Menu>
    </div>
  );
};

export default IngredientLens;