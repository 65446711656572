import React, { useState, useEffect } from 'react';
import IngredientInput from '../casserole-bot/IngredientInput';
import IngredientLens from '../casserole-bot/IngredientLens';
import { recognizeIngredientsFromImage } from '../../services/GoogleCloudService';

interface Ingredient {
  name: string;
}

interface IngredientCaptureProps {
  capturedIngredients: string[];
  setCapturedIngredients: React.Dispatch<React.SetStateAction<string[]>>;
}

const IngredientCapture: React.FC<IngredientCaptureProps> = ({
  capturedIngredients,
  setCapturedIngredients,
}) => {
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const addIngredient = (newIngredients: Ingredient[]) => {
    setIngredients([...ingredients, ...newIngredients]);
  };

  const updateTags = (newIngredients: Ingredient[]) => {
    setIngredients(newIngredients);
  };

  useEffect(() => {
    const updatedIngredients = ingredients.map(ingredient => ingredient.name);
    setCapturedIngredients(updatedIngredients);
  }, [ingredients, setCapturedIngredients]);

  const handleImageSelected = async (imageFile: File) => {
    setIsLoading(true);
    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const imageUrl = event.target?.result as string;
        const detectedIngredients = await recognizeIngredientsFromImage(imageUrl);
        setIngredients([...ingredients, ...detectedIngredients]);
      };
      reader.readAsDataURL(imageFile);
    } catch (error) {
      console.error('Error detecting ingredients:', error);
    } finally {
      setIsLoading(false);
    }
  };  

  return (
    <div className="App">
      <h1>Casserole Bot</h1>
      <div className="input-container">
        <IngredientInput
          ingredients={ingredients}
          onAddIngredient={addIngredient}
          onIngredientsChange={updateTags}
          disabled={isLoading}
        />
        <IngredientLens
          onImageSelected={handleImageSelected}
          onAddIngredient={addIngredient}
          onIngredientsDetected={updateTags}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default IngredientCapture;