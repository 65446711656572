import React, { useState, useEffect } from 'react';
import '../Components.css';

interface Props {
  ingredients: { name: string }[];
  onAddIngredient: (ingredients: { name: string }[]) => void;
  onIngredientsChange: (ingredients: { name: string }[]) => void;
  disabled: boolean;
}

const IngredientInput: React.FC<Props> = ({ ingredients, onAddIngredient, onIngredientsChange, disabled }) => {
  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    const newTags = ingredients.map((ingredient) => ingredient.name);
    setTags((prevTags) => {
      const updatedTags = Array.from(new Set([...prevTags, ...newTags]));
      return updatedTags;
    });
  }, [ingredients]);  

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      addTag();
    }
  };

  const addTag = () => {
    const newTags = inputValue
      .split(',')
      .map((tag) => tag.trim())
      .filter((tag) => tag.length > 0);

    if (newTags.length > 0) {
      const updatedTags = [...tags, ...newTags];
      setTags(updatedTags);
      setInputValue('');
      onIngredientsChange(updatedTags.map((tag) => ({ name: tag })));
    }
  };

  const removeTag = (index: number) => {
    const updatedTags = tags.filter((_, i) => i !== index);
    setTags(updatedTags);
    onIngredientsChange(updatedTags.map((tag) => ({ name: tag })));
  };

  return (
    <div className="IngredientInput">
      <div className="tags-input">
        {tags.map((tag, index) => (
          <span key={index} className="tag">
            {tag}
            <button onClick={() => removeTag(index)}>×</button>
          </span>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          placeholder="Bot hungry... feed ingredients..."
          disabled={disabled}
        />
      </div>
      <p>Manually input ingredients on hand. Separate each ingredient by a comma and press tab or enter when complete.</p>
      <p><span className="or">OR</span></p>
    </div>
  );
};

export default IngredientInput;