import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './App.css';
import IngredientInput from './components/casserole-bot/IngredientInput';
import IngredientLens from './components/casserole-bot/IngredientLens';
import BakeButton from './components/casserole-bot/BakeButton';
import RecipeDisplay from './components/casserole-bot/RecipeDisplay';
import MultiStepForm from './components/household-builder/MultiStepForm';
import { generateRecipe } from './services/OpenAIService';
import { recognizeIngredientsFromImage } from './services/GoogleCloudService';

interface Ingredient {
  name: string;
}

const CasseroleBuilder: React.FC = () => {
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [recipe, setRecipe] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const addIngredient = (newIngredients: Ingredient[]) => {
    setIngredients((prevIngredients) => {
      const uniqueIngredients = newIngredients.filter(
        (newIngredient) =>
          !prevIngredients.some(
            (existingIngredient) => existingIngredient.name === newIngredient.name
          )
      );
      return [...prevIngredients, ...uniqueIngredients];
    });
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result as string;
        resolve(base64Data);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageSelected = async (imageFile: File) => {
    try {
      const imageBase64 = await readFileAsBase64(imageFile);
      const recognizedIngredients = await recognizeIngredientsFromImage(imageBase64);
      addIngredient(recognizedIngredients);
    } catch (error) {
      console.error('Error processing the image:', error);
    }
  };

  const handleBake = async () => {
    setIsLoading(true);
    try {
      const ingredientsText = ingredients.map(ingredient => ingredient.name).join(', ');
      const generatedRecipe = await generateRecipe({ ingredients: ingredientsText });
      setRecipe(generatedRecipe);
      console.log('Success:', generatedRecipe);
    } catch (error) {
      console.log('Error generating recipe:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateTags = (updatedIngredients: Ingredient[]) => {
    setIngredients(updatedIngredients);
  };

  return (
    <div className="App">
      <h1>Casserole Bot</h1>
      <div className="input-container">
      <IngredientInput
        ingredients={ingredients}
        onAddIngredient={addIngredient}
        onIngredientsChange={updateTags}
        disabled={isLoading}
      />
      <IngredientLens
        onImageSelected={handleImageSelected}
        onAddIngredient={addIngredient}
        onIngredientsDetected={updateTags}
        disabled={isLoading}
      />
      </div>
      <BakeButton onClick={handleBake}
        isLoading={isLoading}
        disabled={isLoading}
      />
      {recipe && <RecipeDisplay recipe={recipe} />}
    </div>
  );
};

const MealPlanner: React.FC = () => {

  return (
      <div>
        <MultiStepForm />
      </div>
    );
  };

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <div className='Nav'>
        <nav>
          <ul>
            <li>
              <Link to="/casserole-builder">Casserole Bot</Link>
            </li>
            <li>
              <Link to="/meal-planner">Meal Planner</Link>
            </li>
          </ul>
        </nav>
        </div>

        <Routes>
          <Route path="/casserole-builder" element={<CasseroleBuilder />} />
          <Route path="/meal-planner" element={<MealPlanner />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;