import axios from 'axios';

const openAIApiKey = process.env.REACT_APP_OPENAI_API_KEY;

if (!openAIApiKey) {
  throw new Error('OpenAI API key is missing. Please set REACT_APP_OPENAI_API_KEY in your .env file.');
}

const axiosInstance = axios.create({
  baseURL: 'https://api.openai.com/v1/engines/text-davinci-003/completions',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${openAIApiKey}`
  }
});

interface GenerateRecipeOptions {
  ingredients: string;
  useMealPlanPrompt?: boolean;
}

export async function generateRecipe({ ingredients, useMealPlanPrompt }: GenerateRecipeOptions): Promise<string> {
  try {
    let prompt = `Analyze the following list of ingredients and create a casserole recipe that leverages as much of the available ingredients as possible within reason. You can skip obvious outliers, such as gummy bears found amongst a group of vegetables, as an example. Emphasize the creation of a reasonable and edible dish as opposed to maximizing the use of ingredients. Be sure to begin with a recipe name, then list the ingredients required, followed by a step-by-step list of instructions, and a short narrative about the dish. Here are the initial ingredients to consider: ${ingredients}.`;

    if (useMealPlanPrompt) {
      prompt = ingredients;
    }

    const response = await axiosInstance.post('', {
      prompt: prompt,
      max_tokens: 3000,
      n: 1,
      stop: null,
      temperature: 0.7,
    });

    if (response.data.choices && response.data.choices.length > 0) {
      return response.data.choices[0].text.trim();
    } else {
      throw new Error('No recipe generated.');
    }
  } catch (error) {
    console.error('Error generating recipe:', error);
    throw error;
  }
}