import React, { useState, useCallback } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import IngredientCapture from "./IngredientCapture";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKitchenSet, faMugSaucer, faBreadSlice, faBurger } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Summary from "./Summary";
import "../Components.css";
import Swal from "sweetalert2";

const calculateAge = (birthYear: number, birthMonth: number): number => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  let age = currentYear - birthYear;
  if (currentMonth < birthMonth || (currentMonth === birthMonth && new Date().getDate() < new Date(birthYear, birthMonth - 1).getDate())) {
    age--;
  }

  return age;
};

export interface Meal {
  day: string;
  mealType: string;
  meal?: string;
  active: boolean;
}


export interface HouseholdMember {
    id: number;
    name: string;
    birthMonth: number;
    birthYear: number;
    skillLevel: string;
    isExecutiveChef?: boolean;
    dietaryPreferences?: string[];
    dietaryRestrictions?: string[];
    mealPlan?: Meal[];
    age?: number;
}  

const MultiStepForm: React.FC = () => {
  // State variables
  const [step, setStep] = useState(1);
  const [householdMembers, setHouseholdMembers] = useState<HouseholdMember[]>([]);
  const [currentMember, setCurrentMember] = useState<Partial<HouseholdMember>>({});
  const [isAddingMember, setIsAddingMember] = useState(true);
  const [editingMode, setEditingMode] = useState(false);
  const [ingredientsStep, setIngredientsStep] = useState(false); 
  const [capturedIngredients, setCapturedIngredients] = useState<string[]>([]);
  

  // Handlers for moving between steps
  const goToStep = (newStep: number) => {
    setStep(newStep);
  };

  const nextStep = () => {
    if (step === 1 && currentMember.birthYear && currentMember.birthMonth) {
      const age = calculateAge(currentMember.birthYear, currentMember.birthMonth);
      setCurrentMember({ ...currentMember, age });
    }
    setStep(step + 1);
  };  

  const prevStep = () => {
    goToStep(step - 1);
  };

  // Handler for updating current household member information
  const updateCurrentMember = useCallback((key: keyof HouseholdMember, value: any) => {
    setCurrentMember({ ...currentMember, [key]: value } as Partial<HouseholdMember>);
  }, [currentMember]);

  // Handler for adding current household member to the list
  const addHouseholdMember = () => {
    if (currentMember.name) {
      setHouseholdMembers([
        ...householdMembers,
        {
          ...currentMember,
          id: Date.now(),
          name: currentMember.name || "",
          birthMonth: currentMember.birthMonth!,
          birthYear: currentMember.birthYear!,
          skillLevel: currentMember.skillLevel ?? "",
        },
      ]);
      setCurrentMember({});
      setIsAddingMember(false);
    } else {
      alert("Please provide a name for the household member.");
    }
  };

  // Handlers for editing household members
  const editHouseholdMember = (id: number) => {
    const memberToEdit = householdMembers.find((member) => member.id === id);
    if (memberToEdit) {
      setCurrentMember({ ...memberToEdit });
      goToStep(1);
      setEditingMode(true); // Set editing mode to true
    }
  };

  // Handler for saving changes to an existing household member
  const saveEditedHouseholdMember = () => {
    if (currentMember.id) {
      setHouseholdMembers(
        householdMembers.map((member) =>
          member.id === currentMember.id ? { ...currentMember } as HouseholdMember : member
        )
      );
      setCurrentMember({});
      setEditingMode(false);
    } else {
      alert("An error occurred while updating the household member.");
    }
  };

// Handler for setting the Ingredients step to true
  const goToIngredients = () => {
    setIngredientsStep(true);
  };

  // Handler for deleting a household member
  const deleteHouseholdMember = (id: number, name: string) => {
    Swal.fire({
      title: "Are you sure?",
      html: `Are you sure you want to permanently delete <b>${name}'s</b> record from the household?<br /><br />You can always re-create it from scratch.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result: { isConfirmed: any; }) => {
      if (result.isConfirmed) {
        setHouseholdMembers(householdMembers.filter((member) => member.id !== id));
      }
    });
  };

  // Check if there's an Executive Chef
  const hasExecutiveChef = householdMembers.some((member) => member.isExecutiveChef);

    // Handler to show a tooltip for Executive Chef
    const showTooltip = (e: React.MouseEvent) => {
      const tooltip = document.createElement("div");
      tooltip.className = "tooltip";
      tooltip.innerText = "Executive Chef";
      tooltip.style.position = "absolute";
      tooltip.style.top = `${e.clientY + 10}px`;
      tooltip.style.left = `${e.clientX + 10}px`;
      document.body.appendChild(tooltip);
    };
  
    const hideTooltip = () => {
      const tooltip = document.querySelector(".tooltip");
      if (tooltip) {
        tooltip.remove();
      }
    };

    const mealTypeToIcon: { [key: string]: IconDefinition } = {
      Breakfast: faMugSaucer,
      Lunch: faBreadSlice,
      Dinner: faBurger,
    };
    
  // New function to show custom tooltip
  const showCustomTooltip = (e: React.MouseEvent, text: string) => {
    const tooltip = document.createElement("div");
    tooltip.className = "tooltip";
    tooltip.innerText = text;
    tooltip.style.position = "absolute";
    tooltip.style.top = `${e.clientY + 10}px`;
    tooltip.style.left = `${e.clientX + 10}px`;
    document.body.appendChild(tooltip);
  };

  // Handler for renderMealIcon function
  const renderMealIcon = (member: HouseholdMember, day: string, mealType: string) => {
    const mealPlan = member.mealPlan || [];
    const meal = mealPlan.find((m) => m.day === day && m.mealType === mealType);

    const iconColor = meal?.active ? "#007bff" : "lightgrey";

    // New tooltip text based on meal.active value
    const tooltipText = meal?.active
      ? `Yes for ${mealType} on ${day}`
      : `Excluded from ${mealType} on ${day}`;

    return (
      <FontAwesomeIcon
        icon={mealTypeToIcon[mealType]}
        style={{ color: iconColor }}
        onMouseEnter={(e) => showCustomTooltip(e, tooltipText)} // Show custom tooltip on mouse enter
        onMouseLeave={hideTooltip} // Hide custom tooltip on mouse leave
      />
    );
  };

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <div className="outter-container">
    <h1>Meal Planner</h1>

    {(isAddingMember || editingMode) ? (
      <>
{step === 1 && (
  <Step1
    currentMember={currentMember}
    hasExecutiveChef={hasExecutiveChef}
    updateCurrentMember={updateCurrentMember}
    nextStep={nextStep}
    calculateAge={calculateAge}
  />
)}

        {step === 2 && (
          <Step2
            currentMember={currentMember}
            updateCurrentMember={updateCurrentMember}
            prevStep={prevStep}
            nextStep={nextStep}
          />
        )}

        {step === 3 && (
          <Step3
            currentMember={currentMember}
            updateCurrentMember={updateCurrentMember}
            prevStep={prevStep}
            addHouseholdMember={editingMode ? saveEditedHouseholdMember : addHouseholdMember}
          />
        )}
      </>
      ) : (
        <div className="form-container">

      {householdMembers.length > 0 && (
        <div className="form-container-member">
          <button
            onClick={() => {
              goToStep(1);
              setIsAddingMember(true);
            }}
          >
            Add another household member
          </button>
          <h2>Household Members</h2>
          <table>
            <thead>
              <tr>
                <th></th>
                {days.map((day) => (
                  <th key={day}>{day.substring(0, 3)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {householdMembers.map((member) => (
                <tr key={member.id}>
                  <td>
                    <div className="household-member-container">
                      <button
                        className="delete"
                        onClick={() =>
                          deleteHouseholdMember(member.id, member.name)
                        }
                      >
                        x
                      </button>
                      <p>{member.name}</p>
                      {member.isExecutiveChef && (
                        <span
                          onMouseEnter={showTooltip}
                          onMouseLeave={hideTooltip}
                          className="executive-chef-icon"
                        >
                          <FontAwesomeIcon icon={faKitchenSet} />
                        </span>
                      )}
                      <button
                        id="edit"
                        onClick={() => editHouseholdMember(member.id)}
                      >
                        Edit
                      </button>
                    </div>
                  </td>
                  {days.map((day) => (
                    <td key={day}>
                      {["Breakfast", "Lunch", "Dinner"].map((mealType) => (
                        <span
                          key={mealType}
                          className="meal-icon"
                        >
                          {renderMealIcon(member, day, mealType)}
                        </span>
                      ))}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {householdMembers.length > 0 && !ingredientsStep && ( 
        <button onClick={goToIngredients}>Go to ingredients</button>
      )}
{ingredientsStep && (
  <IngredientCapture
    capturedIngredients={capturedIngredients}
    setCapturedIngredients={setCapturedIngredients}
  />
)}
{householdMembers.length > 0 && (
  <Summary
    householdMembers={householdMembers}
    capturedIngredients={capturedIngredients}
  />
)}
      </div>
    )}
  </div>
);
      };

export default MultiStepForm;