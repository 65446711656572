import React from 'react';
import '../Components.css';

interface Props {
  recipe: string;
}

const RecipeDisplay: React.FC<Props> = ({ recipe }) => {
  return (
    <div className="RecipeDisplay">
      <h2>Your Casserole Recipe</h2>
      <pre>{recipe}</pre>
    </div>
  );
};

export default RecipeDisplay;