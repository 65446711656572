import React from 'react';
import { HouseholdMember } from './MultiStepForm';

interface Step2Props {
    currentMember: Partial<HouseholdMember>;
    updateCurrentMember: (key: keyof HouseholdMember, value: any) => void;
    prevStep: () => void;
    nextStep: () => void;
}

const Step2: React.FC<Step2Props> = ({ currentMember, updateCurrentMember, prevStep, nextStep }) => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        updateCurrentMember(name as keyof HouseholdMember, value);
    };

    return (
        <div className='form-container'>
            <h3>Dietary Information</h3>
            <label htmlFor="dietaryRestrictions">Dietary Restrictions</label>
            <input type="text" id="dietaryRestrictions" name="dietaryRestrictions" value={currentMember.dietaryRestrictions || ''} onChange={handleInputChange} />
            <label htmlFor="dietaryPreferences">Dietary Preferences</label>
            <input type="text" id="dietaryPreferences" name="dietaryPreferences" value={currentMember.dietaryPreferences || ''} onChange={handleInputChange} />
            <div className='row-container'>
            <button onClick={prevStep}>Previous</button>
            <button onClick={nextStep}>Next</button>
            </div>
        </div>
    );
};

export default Step2;
